.App {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.homePage {
    background-color: black;
}

.homePage h1 {
    color: white;
    font-size: 64px;
    margin: 0px;
}

.homePage h2 {
    font-weight: 400;
    margin: 0px;
}

.App-logo {
    height: 20vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mainFrame {
    margin: 0 auto;
    max-width: 100vh;
}

/*home*/
.homeContent {
    margin: 0;
    padding: 0;
    color: silver;
}
.mainLogo {
}
.mainHomeSlogan {
    text-align:center;
}
.mainHomeHeadSpace {
    min-height: 20vh;
}
.mainHomeBottomSpace {
    min-height: 10vh;
}
/*header*/
.mainHeader {
    background-color: #303030;
    color: white;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0;
    border-bottom: 10px solid gold;
}
.legalHeader {
    border-bottom: 10px solid grey;
}
.headerInsignia {
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
    margin: 10px;
}
.headerInsignias {
    margin-bottom: -70px;
    float: right;
    margin-top: -20px;
}
.mainTitle {
    font-weight: 100;
    font-size: 32px;
    margin-bottom: -6px;
}
.mainTitleBold {
    margin-left: 2px;
    color:gold;
}
.mainTitle > a {
    display: block;
    color: white;
    text-decoration: none;
    opacity: 0.75;
}

.mainTitle > a:hover {
    opacity: 1;
}

/*footer*/
.mainFooter {
    background-color: #202020;
    padding-top: 10px;
    padding-bottom: 1px;
    margin-top: auto;
    border-top: 0px solid gold;
}
.mainFooterItems {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    margin-left: -10px;
}
.mainFooterItems > li {
    float: left;
    font-size: larger;
    margin: 10px;
}
.mainFooterItems > li a {
    display: block;
    color: white;
    text-decoration: none;
    opacity: 0.50;
}
.mainFooterItems > li a:hover {
    opacity: 1;
}
.mainCopyright {
    font-weight: 100;
    color: white;
    opacity: 0.5;
}
.homeLink {
}

/*legal*/
.legalPage {
    background-color: whitesmoke;
}
.legalContent {
    color: darkslategray;
    margin: 0;
    padding: 0;
}
